import React from 'react';
import spinner from '../../style/img/ajax-loader.svg';
import { PropTypes } from 'prop-types';
import { Check } from 'react-bootstrap-icons';

const AccionaInput = ({
  label,
  state,
  onChange,
  disabled,
  placeholder,
  type,
  loading,
  verified,
}) => {
  return (
    <span style={{ position: 'relative' }}>
      <input
        style={{ color: 'black' }}
        autoComplete="off"
        placeholder={placeholder}
        type={type}
        id={`input-${label}`}
        disabled={disabled}
        name={label}
        value={state?.value}
        onChange={(event) => onChange(event.target.value)}
      />
      {loading && (
        <img
          src={spinner}
          height="24"
          width="24"
          alt="Loading"
          style={{ position: 'absolute', right: '10px', top: '0px' }}
        ></img>
      )}
      {verified && (
        <Check
          height="24"
          width="24"
          alt="Loading"
          style={{
            position: 'absolute',
            right: '10px',
            top: '0px',
            fill: 'red',
          }}
        />
      )}
    </span>
  );
};

AccionaInput.propTypes = {
  label: PropTypes.string.isRequired,
  state: PropTypes.shape({
    status: PropTypes.oneOf(['success', 'error']),
    value: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  verified: PropTypes.bool,
};

AccionaInput.defaultProps = {
  type: 'text',
  disabled: false,
  loading: false,
  placeholder: undefined,
  verified: false,
};

export default AccionaInput;
