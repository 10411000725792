import { normalizeDate } from './utils';
const hourReg = new RegExp('^[0-2][0-9]:[0-5][0-9](:[0-5][0-9])?$');
export const dateReg = new RegExp(
  '^[0-3]?[0-9]/[0-1]?[0-9]/[0-9]?[0-9]?[0-9][0-9]',
);
export const dateReg2 = new RegExp(
  '^[0-9]?[0-9]?[0-9][0-9]-[0-1]?[0-9]-[0-3]?[0-9]',
);
const numberReg = new RegExp('^[0-9]+$');
const floatReg = new RegExp('^[0-9]+([.,][0-9]+)?$');
const stringReg = new RegExp(/^[\w\s\u00C0-\u00FF,.]*$/);
const emailReg = new RegExp(/^\S+@\S+\.\S+$/);
const REGEXP = {
  hour: hourReg,
  date: { d1: dateReg, d2: dateReg2 },
  integer: numberReg,
  float: floatReg,
  string: stringReg,
  email: emailReg,
  phone: numberReg,
};

const ruleTransformer = (formItem) => {
  switch (formItem.rules?.type) {
    case 'boolean':
      return formItem.value;
    case 'integer':
    case 'float':
      return Number(String(formItem.value).replace(',', '.'));
    case 'date':
      return normalizeDate(formItem.value);
    case 'hour':
      if (formItem.value) {
        let string = String(formItem.value);
        if (string.length <= 5) string += ':00';
        return string;
      } else return null;
    default:
      if (formItem.value === '') return undefined;
      return String(formItem.value);
  }
};

const checkRules = (value, rules) => {
  try {
    let valueAsString = String(value);
    let long = valueAsString.length;
    let valid = true;
    if (long === 0 && rules.min === 0) {
      return 'success';
    }
    if (rules.type === 'moment' && value) {
      return 'success';
    } else if (rules.type === 'boolean') {
      if (value === true || value === false) return 'success';
      else return 'error';
    } else if (
      rules.type === 'string' ||
      rules.type === 'integer' ||
      rules.type === 'float' ||
      rules.type === 'fullString'
    ) {
      valid *= long >= rules.min && long <= rules.max;
    }
    if (valid) {
      if (rules.type === 'date') {
        valid *=
          REGEXP.date.d1.test(valueAsString) ||
          REGEXP.date.d2.test(valueAsString);
      } else if (rules.type !== 'fullString')
        valid *= REGEXP[rules.type].test(valueAsString);
    }
    return valid ? 'success' : 'error';
  } catch {
    return 'error';
  }
};
function subFormIsValidated(object, array) {
  for (const element of array)
    if (object[element].status !== 'success') return false;

  return true;
}

function formIsValidated(object) {
  for (const i in object) if (object[i].status !== 'success') return false;

  return true;
}

function regionsAreSelected(object) {
  for (const i in object) {
    if (!object[i]) return false;
  }
  return true;
}

export {
  checkRules,
  formIsValidated,
  ruleTransformer,
  regionsAreSelected,
  subFormIsValidated,
};
