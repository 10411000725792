import { useEffect, useState } from 'react';
import Step from '../../../../../../../../components/Step';
import AccionaFormItem from '../../../../../../../../components/AccionaFormItem';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import AccionaTimePicker from '../../../../../../../../components/AccionaTimePicker';
import moment from 'moment';
import AccionaDateTimePicker from '../../../../../../../../components/AccionaDateTimePicker';
import {
  updateDateFormat,
  validateIsSameOrBeforeDate,
  validateIsSameOrBeforeTime,
} from '../../../index.utils';
import styles from './index.module.scss';
import { useParams } from 'react-router-dom';

const { wrapper } = styles;

const Delivery = ({ onFinish, preregister, onCancel, stepNumber }) => {
  const intl = useIntl();
  const { model } = useParams();
  const [arrivingTime, setArrivingTime] = useState(
    preregister.arriving_time || moment().format('HH:mm'),
  );
  const [arrivingDate, setArrivingDate] = useState(
    preregister.arriving_date
      ? moment(preregister.arriving_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : undefined,
  );

  const [error, setError] = useState({
    arrivingTime: false,
    arrivingDate: false,
  });

  useEffect(() => {
    if (arrivingDate) {
      setError((prev) => ({ ...prev, arrivingDate: false }));
      const isAValidDate = validateIsSameOrBeforeDate(arrivingDate);
      if (!isAValidDate) {
        setError((prev) => ({
          ...prev,
          arrivingDate: true,
        }));
      }
    }
  }, [arrivingDate]);

  useEffect(() => {
    if (arrivingTime) {
      setError((prev) => ({ ...prev, arrivingTime: false }));
      const isAValidTime = validateIsSameOrBeforeTime(arrivingTime);
      if (!isAValidTime) {
        setError((prev) => ({
          ...prev,
          arrivingTime: true,
        }));
      }
    }
  }, [arrivingTime]);

  return (
    <Step
      stepNumber={stepNumber}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step02',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      content={
        <div className={wrapper}>
          <AccionaFormItem style={error ? { marginBottom: '5px' } : undefined}>
            <AccionaTimePicker
              label="arriving_time"
              onUpdate={() => setArrivingTime(moment().format('HH:mm'))}
              onChange={(inputValue) => setArrivingTime(inputValue)}
              state={{
                value: arrivingTime ?? moment().format('HH:mm'),
                status: error.arrivingTime ? 'error' : 'success',
              }}
            />
          </AccionaFormItem>
          <div className={`${error.arrivingTime ? 'infoError' : ''}`}>
            {error.arrivingTime &&
              intl.formatMessage(
                { id: 'validations.chooseAPreviousTime' },
                { time: moment().format('HH:mm') },
              )}
          </div>
          <AccionaFormItem
            style={error.arrivingDate ? { marginBottom: '5px' } : {}}
          >
            <AccionaDateTimePicker
              label="arriving_date"
              onChange={(inputValue) => {
                setArrivingDate(inputValue);
              }}
              state={{
                value: arrivingDate,
                status: error.arrivingDate ? 'error' : 'success',
              }}
              disabled={model === 'cemex'}
            />
          </AccionaFormItem>
          <div className={`${error.arrivingDate ? 'infoError' : ''}`}>
            {error.arrivingDate &&
              intl.formatMessage(
                { id: 'validations.chooseAPreviousDate' },
                { date: moment().format('DD/MM/YYYY') },
              )}
          </div>
        </div>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          arriving_time: arrivingTime,
          arriving_date: updateDateFormat(arrivingDate),
        });
      }}
      disableNextButton={
        error.arrivingTime ||
        error.arrivingDate ||
        !arrivingTime ||
        !arrivingDate
      }
    />
  );
};

Delivery.propTypes = {
  preregister: PropTypes.shape({
    arriving_date: PropTypes.string,
    arriving_time: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

Delivery.defaultProps = {
  preregister: {},
};

export default Delivery;
