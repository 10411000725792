import moment from 'moment';
import {
  DATABASE_NAME,
  PREREGISTER_TABLE,
  PENDING_PREREGISTERS_TABLE,
  VERSION,
  preRegisterColumns,
} from './data';

export function creationDataBase() {
  const request = indexedDB.open(DATABASE_NAME, VERSION);
  request.addEventListener('upgradeneeded', (event) => {
    var db = event.target.result;
    if (!db.objectStoreNames.contains(PREREGISTER_TABLE)) {
      const preRegisterTable = db.createObjectStore(PREREGISTER_TABLE, {
        keyPath: 'idCache',
        autoIncrement: true,
      });
      preRegisterColumns.forEach((column) =>
        preRegisterTable.createIndex(column, column),
      );
    }
    if (!db.objectStoreNames.contains(PENDING_PREREGISTERS_TABLE)) {
      const pendingPreregistersTable = db.createObjectStore(
        PENDING_PREREGISTERS_TABLE,
        {
          keyPath: 'project',
        },
      );
      preRegisterColumns.forEach((column) =>
        pendingPreregistersTable.createIndex(column, column),
      );
    }
  });
}

export async function getObjectStore(table = PREREGISTER_TABLE) {
  return new Promise(async (resolve, reject) => {
    try {
      const request = indexedDB.open(DATABASE_NAME, VERSION);
      request.onsuccess = async ({ target }) => {
        const database = await target.result;
        const objectStore = database
          .transaction([table], 'readwrite')
          .objectStore(table);
        resolve(objectStore);
      };
      request.onerror = ({ target }) => {
        reject(target.error);
      };
    } catch (error) {
      reject('reject', error);
    }
  });
}

export function countPreDNCache(projectId) {
  return new Promise(async (resolve, reject) => {
    const objStore = await getObjectStore();
    objStore.index('project').count(projectId).onsuccess = ({
      target: result,
    }) => {
      if (result) resolve(result.result);
      else reject();
    };
  });
}

export function addToCache(table = PREREGISTER_TABLE, newRegister) {
  return new Promise(async (resolve, reject) => {
    const objectStore = await getObjectStore(table);
    var response = objectStore.add(newRegister);
    response.onsuccess = (event) => {
      resolve(event.target.result);
    };
    response.onerror = (event) => {
      const error = event.target.error;
      reject({
        errorType: 'ADD_TO_CACHE_ERROR',
        status: error.code,
        statusText: error.message,
      });
    };
  });
}

export function putToCache(table = PREREGISTER_TABLE, newRegister) {
  return new Promise(async (resolve, reject) => {
    const objectStore = await getObjectStore(table);
    var response = objectStore.put(newRegister);
    response.onsuccess = (event) => {
      resolve(event.target.result);
    };
    response.onerror = (event) => {
      const error = event.target.error;
      reject({
        errorType: 'PUT_TO_CACHE_ERROR',
        status: error.code,
        statusText: error.message,
      });
    };
  });
}

export function removeFromCache(table = PREREGISTER_TABLE, id) {
  return new Promise(async (resolve, reject) => {
    const objectStore = await getObjectStore(table);
    var response = objectStore.delete(id);
    response.onsuccess = (event) => {
      resolve(event.target.result);
    };
    response.onerror = (event) => {
      const error = event.target.error;
      reject({
        errorType: 'PUT_TO_CACHE_ERROR',
        status: error.code,
        statusText: error.message,
      });
    };
  });
}

export function getPreRegisterCache(idCache) {
  return new Promise(async (resolve, reject) => {
    const objectStore = await getObjectStore();
    objectStore.get(idCache).onsuccess = (event) => {
      if (event.target.result !== undefined) resolve(event.target.result);
      else
        reject({
          errorType: 'GET_PRE_REGISTER_CACHE_ERROR',
          status: 404,
          statusText: 'Not Found',
        });
    };
  });
}

export function getAllPreDNPid(projectId) {
  return new Promise(async (resolve, reject) => {
    const objectStore = await getObjectStore();

    objectStore.index('project').getAll(Number(projectId)).onsuccess = (
      event,
    ) => {
      if (event.target.result !== undefined) {
        resolve(event.target.result);
      } else reject();
    };
  });
}

export function getAllPreRegisterProjectCache({ size, page, filters }) {
  return new Promise(async (resolve, reject) => {
    const objectStore = await getObjectStore();
    objectStore.index('project').getAll(filters.pid).onsuccess = (event) => {
      if (event.target.result !== undefined) {
        var result = [...event.target.result];
        if (filters.docket_number) {
          result = result.filter(
            (preDN) => preDN.docket_number === filters.docket_number,
          );
        }
        if (filters.sortable) {
          const [key, order] = filters.sortable.split(':');

          if (key === 'docket_number')
            result = result.sort((a, b) =>
              order === 'ASC'
                ? a['docket_number'] > b['docket_number']
                  ? 1
                  : -1
                : a['docket_number'] > b['docket_number']
                  ? -1
                  : 1,
            );
          else if (key === 'arriving_date')
            result = result.sort((a, b) => {
              const timeA = moment(a.arriving_date, 'dd/mm/yyyy');
              const timeb = moment(b.arriving_date, 'dd/mm/yyyy');
              return order === 'ASC'
                ? timeA > timeb
                  ? 1
                  : -1
                : timeA < timeb
                  ? -1
                  : 1;
            });
        } else {
          result = result.sort((a, b) =>
            a['docket_number'] > b['docket_number'] ? 1 : -1,
          );
        }
        resolve({
          data: result.slice((page - 1) * size, page * size),
          total: result.length,
        });
      } else reject();
    };
  });
}

// export function updatePreRegisterCache(preRegisterCache, basedataInfo) {
//   return new Promise(async (resolve, reject) => {
//     const objectStore = await getObjectStore();

//     objectStore.get(preRegisterCache.idCache).onsuccess = (event) => {
//       if (event.target.result !== undefined) {
//         var data = event.target.result;
//         for (var index in preRegisterCache) {
//           // Controlando que json realmente tenga esa propiedad
//           if (preRegisterCache.hasOwnProperty(index)) {
//             data[index] = preRegisterCache[index];
//           }
//           var requestUpdate = objectStore.put(data);
//           requestUpdate.onerror = function (event) {
//             reject();
//           };
//           requestUpdate.onsuccess = function (event) {
//             resolve();
//           };
//         }
//       } else reject();
//     };
//   });
// }

export async function deletePreRegisterCache(idCache) {
  const objectStore = await getObjectStore();
  objectStore.delete(idCache);
}
