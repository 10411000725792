import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Line } from '@ant-design/plots';
import moment from 'moment';
import { useIntl } from 'react-intl';
import chartConfig from './index.config';

const GradientChart = ({ loading, data, monitoring, range, channelRange }) => {
  const intl = useIntl();
  const plot = useRef();
  const sliderRange = useRef([0, 1]);
  const [parsedData, setParsedData] = useState([]);

  const parseTemperatureData = useCallback((data, channel1, channel2) => {
    const dataChannel1 = data.filter((item) => item.channel === channel1);
    const dataChannel2 = data.filter((item) => item.channel === channel2);

    const dictChannel1 = {};
    dataChannel1.forEach((item) => {
      dictChannel1[item.timestamp] = item.temperature;
    });

    const result = [];

    dataChannel2.forEach((item) => {
      if (dictChannel1[item.timestamp] !== undefined) {
        const tempDifference = dictChannel1[item.timestamp] - item.temperature;
        const roundedDifference =
          Math.round((tempDifference + Number.EPSILON) * 10000) / 10000;
        result.push({
          temperature: roundedDifference,
          timestamp: item.timestamp,
        });
      }
    });

    return result;
  }, []);

  useEffect(() => {
    if (!Object.values(channelRange).includes(undefined)) {
      setParsedData(
        parseTemperatureData(data, channelRange[0], channelRange[1]),
      );
    }
  }, [channelRange, data, parseTemperatureData]);

  useEffect(() => {
    const _plot = plot.current;
    const handler = ({ view: { controllers } }) => {
      const { end, start } = controllers[4];
      sliderRange.current = [start, end];
    };
    _plot.getChart().on('slider:mouseup', handler);

    return () => {
      _plot.getChart().off('slider:mouseup', handler);
    };
  }, []);

  useEffect(() => {
    sliderRange.current = [0, 1];
  }, [range]);

  return (
    <>
      <Line
        {...chartConfig}
        data={parsedData}
        loading={loading || Object.values(channelRange).includes(undefined)}
        xAxis={{
          title: {
            text: intl.formatMessage({
              id: 'strength.thermalcontrol.monitorings.view.charts.date',
            }),
          },
          label: {
            offset: 10,
          },
        }}
        yAxis={{
          title: {
            text: intl.formatMessage({
              id: 'strength.thermalcontrol.monitorings.view.charts.temperature',
            }),
          },
        }}
        tooltip={{
          formatter: ({ channel, timestamp, temperature }) => {
            return {
              name: intl.formatMessage({
                id: 'strength.thermalcontrol.monitorings.view.charts.temperature',
              }),
              title: moment(timestamp).format('DD/MM/YYYY HH:mm'),
              value: temperature + 'ºC',
            };
          },
        }}
        slider={{
          formatter: (v) => moment(v).format('DD/MM/YYYY'),
          foregroundStyle: { fill: '#ff000090' },
          height: 30,
          textStyle: { fontWeight: 600, fill: 'black' },
          start: sliderRange.current[0],
          end: sliderRange.current[1],
        }}
        ref={plot}
      />
    </>
  );
};

GradientChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.string,
      maturity: PropTypes.number,
      temperature: PropTypes.number,
      timestamp: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  monitoring: PropTypes.object.isRequired,
  range: PropTypes.arrayOf(PropTypes.instanceOf(moment)).isRequired,
  channelRange: PropTypes.arrayOf(PropTypes.string).isRequired,
};

GradientChart.defaultProps = {
  channelRange: [undefined, undefined],
  loading: false,
  data: [],
};

export default React.memo(GradientChart);
