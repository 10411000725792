import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import arrow from '../../style/img/arrow-anticw-red.svg';

const AccionaTimePicker = ({ state, label, onChange, onUpdate }) => {
  const [activeTimeModal, setActiveTimeModal] = useState('none');
  const [activeScroll, setActiveScroll] = useState(true);

  window.addEventListener('click', function (e) {
    const control = [
      'fas fa-chevron-up',
      'fas fa-chevron-down',
      'timeSelected',
      'timePicker',
    ];
    if (!control.includes(e.target.className)) {
      setActiveTimeModal('none');
    } else if (e.target.className === 'timeSelected') {
      setActiveTimeModal('none');
    }
  });

  const onOpen = () => {
    setActiveTimeModal('');
  };

  useEffect(() => {
    const minuteSelected = document.querySelector('.minutes .timeSelected');
    const minutePosition = minuteSelected.offsetTop - 70;
    document
      .querySelector('.minutes ul')
      .scroll({ top: minutePosition, left: 0, behavior: 'auto' });
    document.querySelector('.minutes ul').style.paddingTop =
      minuteSelected.innerText === '00' ? '35px' : '0px';
    document.querySelector('.minutes ul').style.paddingBottom =
      minuteSelected.innerText === '59' ? '35px' : '0px';

    const hourSelected = document.querySelector('.hours .timeSelected');
    const hourPosition = hourSelected.offsetTop - 70;
    document
      .querySelector('.hours ul')
      .scroll({ top: hourPosition, left: 0, behavior: 'auto' });
    document.querySelector('.hours ul').style.paddingTop =
      hourSelected.innerText === '00' ? '35px' : '0px';
    document.querySelector('.hours ul').style.paddingBottom =
      hourSelected.innerText === '23' ? '35px' : '0px';
  }, [activeTimeModal]);

  const setNewTime = (event, timeString) => {
    const newTime = event.target.innerText;
    let newTimeFormatted;
    event.target.className = 'timeSelected';
    if (timeString === 'hours') {
      newTimeFormatted = newTime + ':' + state.value.split(':')[1];
    } else {
      newTimeFormatted = state.value.split(':')[0] + ':' + newTime;
    }
    onChange(newTimeFormatted);
  };

  const handleScroll = (event, action) => {
    if (activeScroll) {
      const scrollTop =
        event.target.parentElement.querySelector('ul').scrollTop;
      const controlScrollTop =
        event.target.parentElement.querySelector('ul').scrollHeight;
      let newScrollTop = action === 'up' ? scrollTop + 30 : scrollTop - 30;

      if (scrollTop === controlScrollTop - 100 && action === 'up') {
        setActiveScroll(false);
        setTimeout(() => setActiveScroll(true), 700);
        newScrollTop = 0;
      } else if (!scrollTop && action === 'down') {
        setActiveScroll(false);
        setTimeout(() => setActiveScroll(true), 700);
        newScrollTop = controlScrollTop;
      }

      event.target.parentElement.querySelector('ul').scroll({
        top: newScrollTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const getTimeSplit = () => {
    const hourReference = state.value.split(':')[0];
    const minuteReference = state.value.split(':')[1];
    let timeReference, limit;

    const getPicker = (timeString) => {
      if (timeString === 'hours') {
        timeReference = hourReference;
        limit = 23;
      } else {
        timeReference = minuteReference;
        limit = 59;
      }
      const timeSplit = [];
      for (let index = 0; index <= limit; index++) {
        let time = index <= 9 ? '0' + index : index.toString();
        timeSplit.push(
          <li
            className={timeReference === time ? 'timeSelected' : ''}
            key={`${timeString}-${time}`}
            onClick={(event) => setNewTime(event, timeString)}
          >
            {time}
          </li>,
        );
      }
      return timeSplit;
    };

    return (
      <div className="optionsTimePicker" style={{ display: activeTimeModal }}>
        <div className="hoursAndMinute">
          <div className="hours">
            <i
              className="fas fa-chevron-up"
              onClick={(event) => handleScroll(event, 'up')}
            ></i>
            <ul>{getPicker('hours')}</ul>
            <i
              className="fas fa-chevron-down"
              onClick={(event) => handleScroll(event, 'down')}
            ></i>
          </div>
          <div style={{ fontSize: '18px' }}>
            <strong>:</strong>
          </div>
          <div className="minutes">
            <i
              className="fas fa-chevron-up"
              onClick={(event) => handleScroll(event, 'up')}
            ></i>
            <ul>{getPicker('minutes')}</ul>
            <i
              className="fas fa-chevron-down"
              onClick={(event) => handleScroll(event, 'down')}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <input
        type="text"
        name={label}
        className="timePicker"
        readOnly
        value={state.value}
        style={{ textAlign: 'center', color: 'black', width: '80%' }}
        onClick={() => onOpen()}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>{getTimeSplit()}</div>
      </div>
      <button onClick={onUpdate} style={{ width: '20%' }}>
        <img
          style={{ width: '50px', height: '50px' }}
          src={arrow}
          alt="arrow"
        />
      </button>
    </div>
  );
};

AccionaTimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  state: PropTypes.shape({
    status: PropTypes.oneOf(['success', 'error']),
    value: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

AccionaTimePicker.defaultProps = {};

export default AccionaTimePicker;
