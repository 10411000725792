import moment from 'moment';
import 'moment/locale/es';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as ProjectsActions from '../../services/redux/projects/actions';
import ProjectsList from './ProjectsList';

moment.locale(navigator.language.substring(0, 2));

const Main = ({
  getProjectsList,
  projectsList,
  errorMessages,
  createErrors,
  clearErrors,
}) => {
  const navigate = useNavigate();

  return (
    <ProjectsList
      allProjects={projectsList}
      onClick={(project) => {
        navigate(`/app/project/${project.id_project}`);
      }}
    />
  );
};

const mapStateToProps = ({ projects }) => ({
  projectsList: projects.list,
  total: projects.total,
  fetching: projects.fetching,
  errorMessages: projects.errorMessages,
});

const matchDispachToProps = {
  getProjectsList: ProjectsActions.getProjects,
  clearState: ProjectsActions.clearProjectsState,
  clearErrors: ProjectsActions.clearErrors,
  createErrors: ProjectsActions.createErrors,
};

Main.defaultProps = {
  errorMessages: {},
  projectsList: [],
};

Main.propTypes = {
  getProjectsList: PropTypes.func.isRequired,
  errorMessages: PropTypes.shape({
    status: PropTypes.number,
    statusText: PropTypes.string,
  }),
  createErrors: PropTypes.func.isRequired,
  projectsList: PropTypes.arrayOf(PropTypes.object),
  clearErrors: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, matchDispachToProps)(Main);
