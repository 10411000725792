import moment from 'moment';
import { ChartsColors } from '../../../../../../../../../constants/charts';

const chartConfig = {
  xField: 'timestamp',
  yField: 'temperature',
  seriesField: 'channel',
  theme: {
    styleSheet: {
      fontFamily: 'Acciona Font',
    },
  },
  meta: {
    timestamp: {
      tickCount: 5,
      formatter: (v, i) => moment(v).format('DD/MM/YYYY'),
    },
    temperature: {
      formatter: (v) => `${v}ºC`,
    },
  },
  color: ({ channel }) => {
    return ChartsColors[channel[2] - 1];
  },
};

export default chartConfig;
