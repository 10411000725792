import { useEffect, useState } from 'react';
import Step from '../../../../../../../../components/Step';
import AccionaFormItem from '../../../../../../../../components/AccionaFormItem';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import AccionaTimePicker from '../../../../../../../../components/AccionaTimePicker';
import moment from 'moment';
import { validateFinishDischargingTime } from '../../../index.utils';

const FinishDischargingTime = ({
  onFinish,
  preregister,
  onCancel,
  stepNumber,
}) => {
  const intl = useIntl();
  const [finishDischargingTime, setFinishDischargingTime] = useState(
    preregister.finish_discharging_time || moment().format('HH:mm'),
  );
  const [error, setError] = useState({
    finishDischargingTime: false,
  });

  useEffect(() => {
    if (finishDischargingTime) {
      setError((prev) => ({ ...prev, finishDischargingTime: false }));
      const isAValidTime = validateFinishDischargingTime({
        finishDischargingTime,
        startDischargingTime: preregister.start_discharging_time,
      });
      if (!isAValidTime) {
        setError((prev) => ({
          ...prev,
          finishDischargingTime: true,
        }));
      }
    }
  }, [finishDischargingTime, preregister.start_discharging_time]);

  return (
    <Step
      stepNumber={stepNumber}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step06',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      content={
        <>
          <AccionaFormItem style={error ? { marginBottom: '5px' } : undefined}>
            <AccionaTimePicker
              label="finish_discharging_time"
              onUpdate={() =>
                setFinishDischargingTime(moment().format('HH:mm'))
              }
              onChange={(inputValue) => setFinishDischargingTime(inputValue)}
              state={{
                value: finishDischargingTime ?? moment().format('HH:mm'),
                status: error.finishDischargingTime ? 'error' : 'success',
              }}
            />
          </AccionaFormItem>
          <div className={`${error.finishDischargingTime ? 'infoError' : ''}`}>
            {error.finishDischargingTime &&
              intl.formatMessage(
                {
                  id: 'validations.finishDischargingTimeMustBeAfterThanArrivingTime',
                },
                { time: preregister.start_discharging_time },
              )}
          </div>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          finish_discharging_time: finishDischargingTime,
        });
      }}
      disableNextButton={error.finishDischargingTime || !finishDischargingTime}
    />
  );
};

FinishDischargingTime.propTypes = {
  preregister: PropTypes.shape({
    finish_discharging_time: PropTypes.string,
    start_discharging_time: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

FinishDischargingTime.defaultProps = {
  preregister: {},
};

export default FinishDischargingTime;
