import React, { useEffect, useMemo, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Line } from '@ant-design/plots';

import moment from 'moment';
import { useIntl } from 'react-intl';
import chartConfig from './index.config';
import { ChartsColors } from '../../../../../../../../../constants/charts';
import { extractChannelIdentifiers } from '../../../../../../../../../utils/extractChannelIdentifiers';

const MaturityChart = ({ loading, data, YUnit, monitoring, name, range }) => {
  const intl = useIntl();
  const plot = useRef();
  const channelNames = useRef(
    extractChannelIdentifiers([...data.promed, ...data.amb], 'maturity'),
  );
  const selectedChannels = useRef([...channelNames.current]);
  const sliderRange = useRef([0, 1]);

  useEffect(() => {
    const _plot = plot.current;
    const handler = ({ target, ...rest }) => {
      const { id, name } = target.cfg;
      const match = id.match(/ch(\d+)|mean/);
      if (match && name.includes('name')) {
        const channel = match[1] ? parseInt(match[1]) : 'mean';
        if (selectedChannels.current.includes(channel)) {
          selectedChannels.current = selectedChannels.current.filter(
            (c) => c !== channel,
          );
        } else {
          selectedChannels.current = [...selectedChannels.current, channel];
        }
      }
      if (match && name.includes('radio')) {
        const channel = match[1] ? parseInt(match[1]) : 'mean';
        if (selectedChannels.current.includes(channel)) {
          selectedChannels.current = selectedChannels.current.filter(
            (c) => c === channel,
          );
        } else {
          selectedChannels.current = [...channelNames.current];
        }
      }
    };
    _plot.getChart().on('legend-item:click', handler);

    return () => _plot.getChart().off('legend-item:click', handler);
  }, []);

  const allData = useMemo(() => {
    const mergeData = [...data.promed, ...data.amb];
    mergeData.sort(
      (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf(),
    );

    return mergeData;
  }, [data.amb, data.promed]);

  useEffect(() => {
    const channels = extractChannelIdentifiers(allData, 'maturity');
    channelNames.current = channels;
  }, [allData]);

  useEffect(() => {
    const _plot = plot.current;
    const handler = ({ view: { controllers } }) => {
      const { end, start } = controllers[4];
      sliderRange.current = [start, end];
    };
    _plot.getChart().on('slider:mouseup', handler);

    return () => {
      _plot.getChart().off('slider:mouseup', handler);
    };
  }, []);

  useEffect(() => {
    sliderRange.current = [0, 1];
  }, [range]);

  return (
    <>
      <Line
        {...chartConfig}
        data={allData}
        loading={loading}
        xAxis={{
          title: {
            text: intl.formatMessage({
              id: 'strength.hoodedcontrol.monitorings.view.charts.date',
            }),
          },
          label: {
            offset: 10,
          },
        }}
        yAxis={{
          title: {
            text: intl.formatMessage({
              id: 'strength.hoodedcontrol.monitorings.view.charts.' + name,
            }),
          },
        }}
        meta={{
          timestamp: {
            tickCount: 5,
            formatter: (v, i) => moment(v).format('DD/MM/YYYY'),
          },
          maturity: {
            formatter: (v) => `${v}${YUnit}`,
          },
        }}
        legend={{
          offsetX: 35,
          layout: 'horizontal',
          position: 'top-left',
          custom: true,
          selected: channelNames.current.reduce((acc, channel) => {
            if (channel === 'mean') {
              acc.mean = selectedChannels.current.includes(channel);
            } else {
              acc[`ch${channel}`] = selectedChannels.current.includes(channel);
            }
            return acc;
          }, {}),
          itemName: {
            formatter: (v, index) => {
              if (v === 'ch7') {
                return 'Probeta';
              }
              if (v === 'ch8') {
                return 'Ambiente';
              }
              if (v === 'mean') {
                return 'Media';
              }
              return monitoring[v];
            },
          },
          items: channelNames.current.map((i) => {
            if (i !== 'mean') {
              return {
                name: `ch${i}`,
                value: `ch${i}`,
                id: `ch${i}`,
                marker: {
                  symbol: 'hyphen',
                  stroke: ChartsColors[+i - 1],
                  style: { stroke: ChartsColors[+i - 1], lineWidth: 2 },
                },
                unchecked: !selectedChannels.current.includes(i),
              };
            }
            return {
              name: `mean`,
              value: `mean`,
              id: `mean`,
              marker: {
                symbol: 'hyphen',
                stroke: ChartsColors[9],
                style: { stroke: ChartsColors[9], lineWidth: 2 },
              },
              unchecked: !selectedChannels.current.includes('mean'),
            };
          }),
        }}
        tooltip={{
          formatter: ({ channel, timestamp, maturity }) => {
            const getChannelName = () => {
              if (channel === 'ch7') {
                return 'Probeta';
              }
              if (channel === 'mean') {
                return 'Media';
              }
              return monitoring[channel];
            };
            return {
              name: getChannelName(),
              title: moment(timestamp).format('DD/MM/YYYY HH:mm'),
              value: maturity?.toFixed(1) + YUnit,
            };
          },
          customItems: (originalItems) => {
            const items = originalItems
              .filter((i) => i.data.channel !== 'ch8')
              .sort((a, b) => a.data.channel.localeCompare(b.data.channel));
            return items;
          },
        }}
        slider={{
          formatter: (v) => moment(v).format('DD/MM/YYYY'),
          foregroundStyle: { fill: '#ff000090' },
          height: 30,
          textStyle: { fontWeight: 600, fill: 'black' },
          start: sliderRange.current[0],
          end: sliderRange.current[1],
        }}
        ref={plot}
      />
    </>
  );
};

MaturityChart.propTypes = {
  data: PropTypes.shape({
    amb: PropTypes.arrayOf(
      PropTypes.shape({
        channel: PropTypes.string,
        maturity: PropTypes.number,
        temperature: PropTypes.number,
        timestamp: PropTypes.string,
      }),
    ),
    promed: PropTypes.arrayOf(
      PropTypes.shape({
        channel: PropTypes.string,
        maturity: PropTypes.number,
        temperature: PropTypes.number,
        timestamp: PropTypes.string,
      }),
    ),
  }),
  loading: PropTypes.bool,
  YUnit: PropTypes.string,
  monitoring: PropTypes.object.isRequired,
  name: PropTypes.string,
  range: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MaturityChart.defaultProps = {
  YUnit: '',
  loading: false,
  data: [],
  name: 'maturity',
};

export default React.memo(MaturityChart);
