import moment from 'moment';

export const validateStartDischargingTime = ({
  arrivingTime,
  startDischargingTime,
}) => {
  const controlTime = moment('22:00', 'HH:mm');
  let arriving_time = moment(arrivingTime, 'HH:mm');
  let start_discharging_time = moment(startDischargingTime, 'HH:mm');

  if (controlTime.diff(arriving_time) < 0) {
    arriving_time = moment(
      arriving_time.subtract(2, 'hours').format('HH:mm'),
      'HH:mm',
    );
    start_discharging_time = moment(
      start_discharging_time.subtract(2, 'hours').format('HH:mm'),
      'HH:mm',
    );
  }

  return arriving_time.diff(start_discharging_time) <= 0;
};

export const validateFinishDischargingTime = ({
  startDischargingTime,
  finishDischargingTime,
}) => {
  const controlTime = moment('22:00', 'HH:mm');
  let start_discharging_time = moment(startDischargingTime, 'HH:mm');
  let finish_discharging_time = moment(finishDischargingTime, 'HH:mm');

  if (controlTime.diff(start_discharging_time) < 0) {
    start_discharging_time = moment(
      start_discharging_time.subtract(2, 'hours').format('HH:mm'),
      'HH:mm',
    );
    finish_discharging_time = moment(
      finish_discharging_time.subtract(2, 'hours').format('HH:mm'),
      'HH:mm',
    );
  }

  return start_discharging_time.diff(finish_discharging_time) < 0;
};

export const updateDateFormat = (date) => {
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
};

export const validateIsSameOrBeforeDate = (date) => {
  const today = moment().startOf('day');
  const selectedDay = moment(date, 'YYYY-MM-DD');

  return selectedDay.isSameOrBefore(today);
};

export const validateIsSameOrBeforeTime = (date) => {
  const today = moment();
  const selectedDay = moment(date, 'HH:mm');

  return selectedDay.isSameOrBefore(today);
};
