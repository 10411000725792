import { DatePicker } from 'antd';
import { PropTypes } from 'prop-types';
import moment from 'moment';

const AntdDatePicker = ({ style, ...props }) => {
  return (
    <DatePicker
      allowClear={false}
      showTime={{ format: 'HH:mm' }}
      format="DD/MM/YYYY HH:mm"
      showNow={false}
      popupStyle={{ transform: 'scale3d(0.9, 0.9, 0.9)' }}
      style={{
        fontSize: '16px',
        color: ' #666666',
        background: '#f1f1f1',
        borderRadius: 0,
        width: '100%',
        ...style,
      }}
      cellRender={(current, { type, originNode }) => {
        if (type === 'year' || type === 'month') {
          return (
            <div className={`ant-picker-cell-inner ${type}`}>
              {type === 'year'
                ? current.year()
                : moment(current.toDate()).format('MMM')}
            </div>
          );
        }
        return originNode;
      }}
      inputReadOnly
      needConfirm
      {...props}
    />
  );
};

AntdDatePicker.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

AntdDatePicker.defaultProps = {
  style: {},
};

export default AntdDatePicker;
