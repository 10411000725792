import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';

let oneDay = 60 * 60 * 24 * 1000;
let todayTimestamp =
  Date.now() -
  (Date.now() % oneDay) +
  new Date().getTimezoneOffset() * 1000 * 60;

const AccionaDateTimePicker = ({ onChange, state, disabled }) => {
  const intl = useIntl();
  let inputRef = useRef();
  const date = !state.value ? new Date() : new Date(state.value);
  const [year, setStateYear] = useState(date.getFullYear());
  const [month, setStateMonth] = useState(date.getMonth());
  const [selectedDay, setStateSelectedDay] = useState(todayTimestamp);
  const [showDatePicker, setStateShowDatePicker] = useState(false);
  const daysWeek = [
    intl.formatMessage({ id: 'monday' }),
    intl.formatMessage({ id: 'tuesday' }),
    intl.formatMessage({ id: 'wednesday' }),
    intl.formatMessage({ id: 'thursday' }),
    intl.formatMessage({ id: 'friday' }),
    intl.formatMessage({ id: 'saturday' }),
    intl.formatMessage({ id: 'sunday' }),
  ];
  const daysMap = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const monthMap = [
    intl.formatMessage({ id: 'january' }),
    intl.formatMessage({ id: 'february' }),
    intl.formatMessage({ id: 'march' }),
    intl.formatMessage({ id: 'april' }),
    intl.formatMessage({ id: 'may' }),
    intl.formatMessage({ id: 'june' }),
    intl.formatMessage({ id: 'july' }),
    intl.formatMessage({ id: 'august' }),
    intl.formatMessage({ id: 'september' }),
    intl.formatMessage({ id: 'october' }),
    intl.formatMessage({ id: 'november' }),
    intl.formatMessage({ id: 'december' }),
  ];
  const controlClass = [
    'classDefault',
    'mdp-container',
    'mdpc-head',
    'mdpc-body',
    'mdpch-button',
    'mdpchb-inner',
    'mdpchbi-left-arrows',
    'mdpchbi-left-arrow',
    'mdpch-container',
    'mdpchc-year',
    'mdpchc-month',
    'mdpchbi-right-arrow',
    'mdpchbi-right-arrows',
    'c-container',
    'cc-head',
    'cch-name',
    'cc-body',
    'c-day-container',
    'cdc-day',
  ];
  const controlButtonPhoto = [
    'icon fas fa-camera iconPhoto',
    'photoButton',
    'titlePhoto',
  ];

  window.addEventListener('click', (e) => {
    if (
      e.target.type !== 'date' &&
      controlClass.indexOf(e.target.className) < 0
    ) {
      setStateShowDatePicker(false);
    }
    if (
      e.target.className !== '' &&
      controlButtonPhoto.indexOf(e.target.className) < 0
    ) {
      e.preventDefault();
    }
  });

  useEffect(() => {
    if (!state.value)
      inputRef.current.value = getDateStringFromTimestamp(selectedDay);
    else {
      const dateSplit = state.value.split('-');
      const new_selectedDay = new Date(
        dateSplit[0],
        dateSplit[1] - 1,
        dateSplit[2],
      ).getTime();
      setStateSelectedDay(new_selectedDay);
      inputRef.current.value = state.value;
    }
    updateDateFromInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDayDetails = (
    index,
    numberOfDays,
    firstDay,
    year_parametre,
    month_parametre,
  ) => {
    let date = index - firstDay;
    let day = index % 7;
    let prevMonth = month_parametre - 1;
    let prevYear = year_parametre;

    if (prevMonth < 0) {
      prevMonth = 11;
      prevYear--;
    }

    let prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
    let _date =
      (date < 0 ? prevMonthNumberOfDays + date : date % numberOfDays) + 1;
    let new_month = date < 0 ? -1 : date >= numberOfDays ? 1 : 0;
    let timestamp = new Date(year_parametre, month_parametre, _date).getTime();

    return {
      date: _date,
      day,
      new_month,
      timestamp,
      dayString: daysMap[day],
    };
  };

  const getNumberOfDays = (year_parametre, month_parametre) => {
    return 40 - new Date(year_parametre, month_parametre, 40).getDate();
  };

  const getMonthDetails = (year_parametre, month_parametre) => {
    let firstDay = new Date(year_parametre, month_parametre).getDay() - 1;
    let numberOfDays = getNumberOfDays(year_parametre, month_parametre);
    let monthArray = [];
    let rows = 6;
    let currentDay = null;
    let index = 0;
    let cols = 7;

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        currentDay = getDayDetails(
          index,
          numberOfDays,
          firstDay,
          year_parametre,
          month_parametre,
        );
        monthArray.push(currentDay);
        index++;
      }
    }
    return monthArray;
  };

  const [monthDetails, setStateMonthDetails] = useState(
    getMonthDetails(year, month),
  );

  const isCurrentDay = (day) => {
    return day.timestamp === todayTimestamp;
  };

  const isSelectedDay = (day) => {
    return day.timestamp === selectedDay;
  };

  const getDateFromDateString = (dateValue) => {
    let dateData = dateValue.split('-').map((d) => parseInt(d, 10));
    if (dateData.length < 3) return null;

    let year = dateData[0];
    let month = dateData[1];
    let date = dateData[2];
    return { year, month, date };
  };

  const getMonthStr = (month) => monthMap[month] || 'Month';

  const getDateStringFromTimestamp = (timestamp) => {
    let dateObject = new Date(timestamp);
    let month = dateObject.getMonth() + 1;
    let date = dateObject.getDate();
    return (
      dateObject.getFullYear() +
      '-' +
      (month < 10 ? '0' + month : month) +
      '-' +
      (date < 10 ? '0' + date : date)
    );
  };

  const setDate = (dateData) => {
    let new_selectedDay = new Date(
      dateData.year,
      dateData.month - 1,
      dateData.date,
    ).getTime();
    setStateSelectedDay(new_selectedDay);
  };

  const updateDateFromInput = () => {
    let dateValue = inputRef.current.value;
    onChange(getDateStringFromTimestamp(dateValue));
    let dateData = getDateFromDateString(dateValue);

    if (dateData !== null) {
      setStateYear(dateData.year);
      setStateMonth(dateData.month - 1);
      setStateMonthDetails(getMonthDetails(dateData.year, dateData.month - 1));
      setDate(dateData);
    }
  };

  const setDateToInput = (timestamp) => {
    let dateString = getDateStringFromTimestamp(timestamp);
    inputRef.current.value = dateString;
  };

  const onDateClick = (day) => {
    setStateSelectedDay(day.timestamp);
    setDateToInput(day.timestamp);
    onChange(getDateStringFromTimestamp(day.timestamp));
  };

  const setYear = (offset) => {
    let new_year = year + offset;
    let new_month = month;
    setStateYear(new_year);
    setStateMonthDetails(getMonthDetails(new_year, new_month));
  };

  const setMonth = (offset) => {
    let new_year = year;
    let new_month = month + offset;
    if (new_month === -1) {
      new_month = 11;
      new_year--;
    } else if (new_month === 12) {
      new_month = 0;
      new_year++;
    }
    setStateYear(new_year);
    setStateMonth(new_month);
    setStateMonthDetails(getMonthDetails(new_year, new_month));
  };

  const renderCalendar = () => {
    let days = monthDetails.map((day, index) => {
      return (
        <div
          className={
            'c-day-container ' +
            (day.new_month !== 0 ? ' disabled' : '') +
            (isCurrentDay(day) ? ' highlight' : '') +
            (isSelectedDay(day) && day.new_month === 0 ? ' highlight-red' : '')
          }
          key={index}
        >
          <div className="cdc-day">
            <span onClick={() => onDateClick(day)}>{day.date}</span>
          </div>
        </div>
      );
    });

    return (
      <div className="c-container">
        <div className="cc-head">
          {daysWeek.map((d, i) => (
            <div key={i} className="cch-name">
              {d}
            </div>
          ))}
        </div>
        <div className="cc-body">{days}</div>
      </div>
    );
  };

  return (
    <div className="MyDatePicker">
      <div
        className="mdp-input"
        onClick={() => {
          !disabled && setStateShowDatePicker(!showDatePicker);
        }}
      >
        <input
          className={`inputDate ${disabled ? 'disabled' : ''}`}
          type="date"
          id="input-date"
          onChange={updateDateFromInput}
          ref={inputRef}
        />
      </div>
      {showDatePicker ? (
        <div className="mdp-container">
          <div className="mdpc-head">
            <div className="mdpch-button">
              <div className="mdpchb-inner" onClick={() => setYear(-1)}>
                <span className="mdpchbi-left-arrows"></span>
              </div>
            </div>
            <div className="mdpch-button">
              <div className="mdpchb-inner" onClick={() => setMonth(-1)}>
                <span className="mdpchbi-left-arrow"></span>
              </div>
            </div>
            <div className="mdpch-container">
              <div className="mdpchc-year">{year}</div>
              <div className="mdpchc-month">{getMonthStr(month)}</div>
            </div>
            <div className="mdpch-button">
              <div className="mdpchb-inner" onClick={() => setMonth(1)}>
                <span className="mdpchbi-right-arrow"></span>
              </div>
            </div>
            <div className="mdpch-button" onClick={() => setYear(1)}>
              <div className="mdpchb-inner">
                <span className="mdpchbi-right-arrows"></span>
              </div>
            </div>
          </div>
          <div className="mdpc-body">{renderCalendar()}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

AccionaDateTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  state: PropTypes.shape({
    status: PropTypes.oneOf(['success', 'error']),
    value: PropTypes.string,
  }).isRequired,
};

AccionaDateTimePicker.defaultProps = {
  disabled: false,
};

export default AccionaDateTimePicker;
